@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";
@import '../../globalStyles/responsiveLayout/spacers.css';
@import '../../globalStyles/colors/classes.css';
@import '../../globalStyles/fonts/classes.css';

.requirementsChecklist--wrapper {
    margin-bottom: var(--margin);
}

.requirementsChecklist--item {
    margin-bottom: calc(-1 * var(--margin));
    display: flex;
    flex-direction: row;
    align-items: center;

}

.requirementsChecklist--icon {
    padding-top: 5px;
    padding-right: 10px;
}
