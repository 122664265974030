@import '../../globalStyles/colors/classes.css';
@import '../../globalStyles/colors/vars.css'; 


.primary {
    stroke: var(--brand);
}

.secondary {
    stroke: var(--textPrimary);
}

.tertiary {
    stroke: var(--textPrimary);
}

.success {
    stroke: var(--success);
}

.error {
    stroke: var(--error);
}