@import '../../globalStyles/colors/vars.css';



.primaryFill {
    fill: var(--textPrimary);
}

.brandFill {
    fill: var(--brand);
}