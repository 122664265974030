@import '/src/components/globalStyles/colors/vars.css';
@import '/src/components/globalStyles/fonts/classes.css';
@import '/src/components/globalStyles/responsiveLayout/vars.css';

input[id="modeTogglerInner"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.modeTogglerLabel {
  width: calc(var(--app-outer-margin) / 2);
  cursor: pointer;
  text-indent: -9999px;
  width: 36px;
  height: 18px;
  background: var(--grey01);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  --webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  position: relative;
}

.modeTogglerLabel:after {
  content: "";
  background: var(--neutralWhite);
  width: 12px;
  height: 12px;
  --webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 200ms;
}

input:checked + .modeTogglerLabel {
  background-color: var(--success);
}

input:checked + .modeTogglerLabel:after {
  left: calc(100% - 3px);
  --webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.modeTogglerInner {
  position: absolute;
  bottom: 10;
  left: 10;
  
}

/* .modeTogglerOuter {
} */
