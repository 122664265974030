@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";

.coverLetterHighlightedText {
    color: var(--brand);
    /* color:blue */
}

.coverLetterModalWrapper {
    max-width: calc(100% - var(--margin));
    margin-top: var(--sm-margin);
    padding: 5%;
    background-color: var(--bgSecondary);
    border-radius: 24px;
}

.coverLetterModal {
    max-width: calc(100% - var(--margin));
    margin-right: auto;
    padding: var(--padding);
}

.coverLetterHeader {
    color: var(--textPrimary);
    margin-top: var(--sm-margin);
    margin-bottom: var(--sm-margin);
    text-align: center;
}

.coverLetterSubheader {
    color: var(--textPrimary);
    margin-bottom: var(--sm-margin);
    text-align: center;
}

.coverLetterSubheaderCaption {
    color: var(--textTertiary);
    margin-bottom: var(--sm-margin);
}

.coverLetterModal--loaderHeader {
    margin-bottom: var(--sm-margin);
}

/* .coverLetterModal--loaderSubheader {

} */