@import '../../globalStyles/colors/vars.css'; 
@import '../../globalStyles/responsiveLayout/vars.css'; 

.addPositionButtonWrapper {
    display: flex;
    flex-direction: row;
    gap: 5px!important;
    align-items: center;
    color: var(--brand);
    padding: 5px 10px;
    
    transition: .2s ease;
}

.positionsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.formsDropdownListItemWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
} 

.addPositionButtonWrapper:hover {
    color: var(--brandHover);
}

.addPositionButtonWrapper svg path {
    stroke: var(--brand);
}

.addPositionButtonWrapper:hover svg path {
    stroke: var(--brandHover);
}

.addPositionButtonWrapper:active {
    color: var(--brandActive);
}

.addPositionButtonWrapper:active svg path {
    stroke: var(--brandActive);
}

.trashIconWrapper {
    max-width: 24px;
    max-height: 24px;
    padding: 0 10px;
}

.trashIconWrapper svg path {
    stroke: var(--textTertiary);

    transition: .2s ease;
}

.trashIconWrapper:hover svg path {
    stroke: var(--error);

    transition: .2s ease;
}

.seperator {
    max-width: 100%;
    height: 1px;
    background-color: var(--stroke);
    margin-bottom: 10px;
    margin-top: 5px;
}