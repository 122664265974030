@import '../../globalStyles/responsiveLayout/vars.css';
@import '../../globalStyles/colors/vars.css';
@import '../../globalStyles/fonts/classes.css';


a {
    text-decoration: none;
    color: unset;
}

.navbar.outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--bgPrimary);
    border-bottom: 1px solid var(--stroke);
    height: 50px;
    position: sticky;
    z-index: 1002;
    top: 0;
}

.visible {
    transform: translateY(0);

    transition: 300ms ease;
}

.hidden {
    transform: translateY(-101%);

    transition: 300ms ease;
}

.navbar.inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1003;
    margin-left: var(--app-outer-margin);
	margin-right: var(--app-outer-margin);
}

.navbar.logo {
    align-self: center;
    width: 100px;
    z-index: 9999;
}

.navbar.links {
    display: flex;
    flex-direction: row;
    color: var(--brand);
    list-style-type: none;
    user-select: none;
    align-items: center;
    gap: 20px;
}

.navbar.linkWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    overflow: hidden; 
    cursor: pointer;
    transition: 200ms ease;
}

.navbar.linkWrapper a {
    color: var(--textPrimary);

    transition: 200ms ease;
}

.navbar.linkWrapper:hover a {
    color: var(--brand);

    transition: 200ms ease;
}

.navbar.linkWrapper:active {
    transform: translateY(2px);
    color: var(--brandActive);

    transition: 200ms ease;
}

.navbar.linkWrapper:active a {
    color: var(--brandHover);

    transition: 200ms ease;
}

.navbar.linkWrapper:active .navbar.underline {
    width: 10px;
    background-color: var(--brandActive);
}

.navbar.underline {
    height: 1.5px;
    width: 100%;
    background-color: var(--brand);
}

.navbar.linkWrapper .navbar.underline {
    transform: translateX(-101%);

    transition: 200ms ease;
}

.navbar.linkWrapper:hover .navbar.underline {
    transform: translateX(0%);

    transition: 200ms ease;
}

.navbar.linksDivider {
    height: 24px;
    width: 1px;
    background-color: var(--stroke);
}

@media (min-width: 600px) {
    .navbar.outer {
        height: 100px;
    }
    .navbar.logo {
        width: 140px;
    }
}

.navBar--logoImg {
    max-width: 100%; 
    cursor: pointer;
}


.navbar.links.desktop {
    display: flex;
}

.navbar.links.mobile {
    display: none;
}
