@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";
@import '../../globalStyles/responsiveLayout/spacers.css';
@import '../../globalStyles/colors/classes.css';
@import '../../globalStyles/fonts/classes.css';

.resumeFormSubheader {
    color: var(--textSecondary);
    margin-top: var(--sm-margin);
    margin-bottom: var(--sm-margin);
}

.skillsSelectorWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    user-select: none;
    gap: 10px;
    justify-content: center;
    padding: 10px 0px;
}


.skillsSelectorOption {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6px;
    border-radius: 100px;
    overflow: hidden;
    border: 2px solid var(--bgTertiary);
    background-color: var(--bgSecondary);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    white-space: nowrap;
    min-width: min-content;
    max-height: 40px;
    max-width: 30%;
    color: var(--textPrimary);

    transition: .2s ease;
}

.skillsSelectorOption:hover {
    background-color: var(--bgTertiary);
}

.skillsLabel {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    justify-content: center;
    align-content: center;
}

.skillsLabel input[type='checkbox'] {
    display: none;
    position: absolute;
}

.active {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 6%;
    background-color: var(--brand);
    border: 2px solid transparent;
    color: var(--neutralWhite);

    transition: .2s ease;
}

.active:hover {
    background-color: var(--brandHover);
}

.skillsLabel.disable {
    background-color: var(--bgTertiary);
    color: var(--textTertiary);
    cursor: not-allowed;
    /* Add any additional styling for disabled options */
}
/* .iconWrapper {
    overflow: hidden;
    position: absolute;
    padding: 0;
}

.skillsIcon {
    transform: translateY(-101%);

    transition: .2s ease;
}

.skillsIcon path {
    stroke: var(--neutralWhite);
}

.active .skillsIcon {
    transform: translateY(0%);

    transition: .2s ease;
}

.active .iconWrapper {
    position: relative;
} */