.buttonWrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    width: 100%;
}

.grow {
    flex-grow: 1;
    width: 100%;
}