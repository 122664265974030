@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";
@import '../../globalStyles/responsiveLayout/spacers.css';
@import '../../globalStyles/colors/classes.css';
@import '../../globalStyles/fonts/classes.css';


.CLLMonthYearInputWrapper {
    min-width: 100%;
    max-width: 100%;

    transition: .2s ease;
}

.halfInputWrapper {
    display: flex;
    flex-direction: row;
    min-width: 100%;
}

.mainWrapper {
    width: 100%;
}

.growInput {
    width: 100%;
}

.checkboxInput {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    /* width: calc(20px * var(--normalizer)); */
    width: 20px;
    /* height: calc(20px * var(--normalizer)); */
    height: 20px;
    border: 2px solid var(--stroke);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.checkboxInput:checked {
    background-color: var(--brand);
    border-color: var(--brandHover);
}

.checkboxInput:checked::before {
    content: '\2713';
    position: absolute;
    font-size: 16px;
    color: var(--neutralWhite);
}

.checkboxWrapper {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    padding: 5px 0px;
    position: absolute;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: var(--textPrimary);
    background-color: var(--grey01);
    border: 2px solid transparent;
}

.checkboxLabel {
    color: var(--textSecondary);
    user-select: none;
}

.formsQuestionSubLabel.error {
    text-overflow: clip !important;
}