@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";

.valueProps--header {
    text-align: center;

}

.valueProps--subHeader {
    text-align: justify;
}

.valuePropsImg--path {
    fill: var(--textPrimary);
}

.valueProps--textContainer {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    /* text-align: justify; */
}



.valueProps--wrapper {
    height: 100%;
}

.valueProps--imgContainer {
    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 24px;

} 

.valueProps--img {
    max-width: 100%;
    max-height: 100%;
    /* background-color: blueviolet; */
    align-self: center;
    

    

}

/* tablets 378px - 700px */
@media (min-width: 378px) {
    .valueProps--imgContainer {
        height: 300px;
    
    } 
}

/* desktops & screens 700px - 970px */
@media (min-width: 700px) {
    /* .valueProps--imgContainer {
        height: 200px;
    
    }  */

}
