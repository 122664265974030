@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";


.satisfactionButton--wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px;
    flex-grow: 1;
    border-radius: 12px;
    /* margin: top right bottom left */
    margin: var(--sm-margin) var(--sm-margin);

    /* button effects */
    cursor: pointer;
    transition: 200ms ease-in-out;
    background-color: var(--brandLight);
}

.satisfactionButton--wrapper:hover {
    background-color: var(--brandHover);
    color: var(--neutralWhite);
}

.satisfactionButton--wrapper:hover path {
    stroke: var(--neutralWhite);
}

.satisfactionButton--wrapper:active {
    background-color: var(--brandActive);
    transition: 200ms ease;
}


.satisfactionButton--wrapper.animate{
    animation-name: select-rating;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.satisfactionButton--wrapper.animate path {
    stroke: var(--neutralWhite);
}

.satisfactionButton--wrapper.animate:hover{
    background-color: var(--brand);
}


@keyframes select-rating {
    0% {
        flex-grow: 1;
        background-color: var(--brand);
    }
    50% {
        flex-grow: 2;
        background-color: var(--brand);
    }
    100% {
        flex-grow: 2;
        background-color: var(--brand);
    }
}
