@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";
@import '../../globalStyles/responsiveLayout/spacers.css';
@import '../../globalStyles/colors/classes.css';
@import '../../globalStyles/fonts/classes.css';

.skillsSelectorOption.disabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--grey1);
    border: 2px solid transparent;
    text-align: center;
} 