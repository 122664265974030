@import '../../globalStyles/colors/vars.css'; 
@import '../../globalStyles/responsiveLayout/vars.css'; 
@import '../../globalStyles/colors/classes.css';

.formsTagsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.formsTag {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    margin-bottom: 8px;
}

.formsTagRemoveButton {
    padding: 8px;
    margin-right: 4px;
    height: 36px;
    font-size: 20px;
}

.formsTag span {
    padding-left: 4px;

}

.formsDropdown {
    position: relative;
}

.formsDropdownList {
    border-radius: 10px;
    position: absolute;
    top: 55px;
    width: 100%;
    max-width: 100%;
    background-color: var(--bgPrimary);
    padding: 10px 0px;
    z-index: 111;
}

.formsDropdownListItem {
    padding: 6px 12px;
    color: var(--textSecondary);
    cursor: pointer;
    z-index: 112;
}

.formsDropdownListItem:hover {
    background-color: var(--bgSecondary);
    color: var(--textPrimary);
}

.formsIconWrapper {
    position: absolute;
    right: 0;
    top: 2px;
    cursor: default;
}


.activeIcon path {
    stroke: var(--textTertiary);
    transition: .2s ease;
}

.defaultIcon path {
    stroke: var(--brand);
    transition: .2s ease;
}


