@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";
@import '../../globalStyles/responsiveLayout/spacers.css';
@import '../../globalStyles/colors/classes.css';
@import '../../globalStyles/fonts/classes.css';

.positionsModalOverlay {
    position: fixed;
    display: flex;
    z-index: 1111;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    max-width: 100vw;
    width: 100%;
    max-height: 100vh;
    height: 100%;
    backdrop-filter: blur(5px);
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.positionsModalOverlay.visible {
    opacity: 1;

    transition: opacity 0.3s ease;
}

.skillsWrapper {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}