@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";
/* 
.signInModalWrapper {
    width: 100%;
    background-color: var(--bgTertiary);
    min-height: 300px;
} */

.signInFormWrapper {
    background-color: var(--bgPrimary);
    padding: 5%;
    border-radius: 12px;
    box-shadow: var(--elevation01);
}

.signInFormHeader {
    color: var(--textPrimary);
    margin-top: var(--sm-margin);
}

.signInFormSubheader {
    color: var(--textSecondary);
    margin-top: var(--sm-margin);
    margin-bottom: var(--sm-margin);
}