@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";
@import '../../globalStyles/responsiveLayout/spacers.css';
@import '../../globalStyles/colors/classes.css';
@import '../../globalStyles/fonts/classes.css';


.descriptiveLoaderWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.loaderDescriptionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  /* Apply cubic-bezier easing */
}

.loaderBar {
  width: calc(160px / 0.707);
  height: 10px;
  background: var(--bgSecondary);
  border-radius: 10px;
  border: 1px solid var(--brand);
  position: relative;
  overflow: hidden;
}

.loaderBar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 5px;
  background: repeating-linear-gradient(45deg, var(--brandActive) 0 30px, var(--brand) 0 40px) right/200% 100%;
  animation: fillProgress 24s ease-in-out infinite, lightEffect 2s infinite linear;
  animation-fill-mode: forwards;
}

@keyframes fillProgress {
  0% {
    width: 0;
  }
  33% {
    width: 33.333%;
  }
  66% {
    width: 66.67%;
  }
  100% {
    width: 100%;
  }
}

/* @keyframes i3 {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
} */

@keyframes lightEffect {
  0%, 20%, 40%, 60%, 80%, 100% {
    background: repeating-linear-gradient(45deg, var(--brandActive) 0 30px, var(--brand) 0 40px) right/200% 100%;
  }
  10%, 30%, 50%, 70%, 90% {
    background: repeating-linear-gradient(45deg, var(--brandActive) 0 30px, var(--brand) 0 40px, rgba(255, 255, 255, 0.3) 0 40px) right/200% 100%;
  }
}

.loaderLabelWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: start;
  padding-left: 12px;
  gap: 4px;
  color: var(--textSecondary);
}


.dotAnimation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 2px;
  width: 16px;
}

.dotAnimation span {
  display: inline-block;
  width: 0;
  animation: dotAnimation 0.5s ease infinite;
  animation-fill-mode: both; /* Keep the first and last keyframe state */
}

.dotAnimation span:nth-child(1) {
  animation-delay: 0s;
}

.dotAnimation span:nth-child(2) {
  animation-delay: 0.2s;
}

.dotAnimation span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dotAnimation {
  0% {
    width: 0;
  }

  100% {
    width: 6px;
  }
}