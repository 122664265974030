@import '../../globalStyles/responsiveLayout/vars.css';
@import '../../globalStyles/colors/vars.css';
@import '../../globalStyles/fonts/classes.css';


.footer--outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--bgPrimary);
    border-top:  1px solid var(--stroke);
    margin-top: 50px;
}

.footer--inner {
    display: flex;
    flex-direction: column;
    margin-left: var(--app-outer-margin);
	margin-right: var(--app-outer-margin);
    margin-top: var(--margin);
    margin-bottom: var(--margin);
}

.footer--content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: var(--margin);
    margin-top: var(--margin);
    white-space: nowrap;
}

.footer--content .titleSmall {
    font-weight: 500;
}

.footer--marginBottom {
    margin-bottom: 5px;
    flex-grow: 2;
}

.footer--linkWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    gap: 10px;
}

.footer--link {
    color: var(--brand);
    text-decoration: none;
    user-select: none;
    position: relative;
    padding-bottom: 0.25rem;
    box-sizing: border-box;

    transition: 100ms ease;
}

.footer--link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--brand);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 300ms ease-in-out 0s;
    bottom: 0;
    left: 0;
}

.footer--link:hover::before {
    visibility: visible;
    transform: scaleX(1);
}

.footer--link:hover {
    color: var(--brandHover);

    transition: 200ms ease;
}

.footer--link:active {
    color: var(--brandActive);
}

.link {
    width: auto;
    padding: 3px 0px;
}

.spacer {
    height: 1px;
    background-color: var(--stroke);
    width: 100%;
}

.link.horizontal {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-bottom: 20px;
}

.footer--captionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer--captionWrapper.subWrapper {
    padding: 20px 0px;
}

.footer--link.labelSmall {
    color: var(--textPrimary);
}

.labelSmall {
    color: var(--textPrimary);
}

.titleSmall {
    color: var(--textPrimary);
}

.footnote {
    color: var(--textPrimary);
}


@media (min-width: 700px) {
    .footer--content {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .footer--logo {
        align-self: flex-start;
        width: 100px;
    }
    
}
