@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";
@import '../../globalStyles/responsiveLayout/spacers.css';
@import '../../globalStyles/colors/classes.css';
@import '../../globalStyles/fonts/classes.css';


.resumeBuilderHighlightedText {
    color: var(--brand);
}

.resumeBuilderModalWrapper {
    max-width: calc(100% - var(--margin));
    margin-top: var(--sm-margin);
    padding: 5%;
    background-color: var(--bgSecondary);
    border-radius: 24px;
}

.resumeBuilderModal {
    max-width: calc(100% - var(--margin));
    margin-right: auto;
    padding: var(--padding);
}

.resumeBuilderHeader {
    color: var(--textPrimary);
    margin-top: var(--sm-margin);
    margin-bottom: var(--sm-margin);
    text-align: center;
}

.resumeBuilderSubheader {
    color: var(--textPrimary);
    margin-bottom: var(--sm-margin);
    text-align: center;
}

.resumeBuilderSubheaderCaption {
    color: var(--textTertiary);
    margin-bottom: var(--sm-margin);
}

.resumeBuilderModal--loaderHeader {
    margin-bottom: var(--sm-margin);
}
