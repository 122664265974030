@import '/src/components/globalStyles/responsiveLayout/vars.css';
@import '/src/components/globalStyles/colors/vars.css';
/*
mobile grids:
all grid items span the entire width of the screen
*/
.gridContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: calc(100% + var(--margin));
	margin-top: var(--margin);
	/* gap: 24px; adding a gap fucks up our grid system, don't do it.*/
}

.gridItem {
	margin-right: var(--margin);
	margin-bottom: var(--margin);
}
.gridItem.oneFourth {
	width: calc(100% - var(--margin));
	/* max-width: calc(100% - var(--margin)); */

}

.gridItem.oneThird {
	width: calc(100% - var(--margin));
	/* max-width: calc(100% - var(--margin)); */

}

.gridItem.oneThird.withTwoThirds {
	width: calc(100% - var(--margin));
	/* max-width: calc(100% - var(--margin)); */

}

.gridItem.twoThirds {
	width: calc(100% - var(--margin));
	/* max-width: calc(100% - var(--margin)); */

}

.gridItem.oneHalf {
	width: calc(100% - var(--margin));
	/* max-width: calc(100% - var(--margin)); */

}

.gridItem.oneWhole {
	width: calc(100% - var(--margin));
	/* max-width: calc(100% - var(--margin)); */

}

.testingGridContainers {
	box-shadow: inset 0px 0px 0px 3px var(--textPrimary);
	color: var(--textPrimary);
}

.testingGridItems {
	min-height: 80px;
	padding: var(--padding);
	box-shadow: inset 0px 0px 0px 3px var(--textPrimary);
	color: var(--textPrimary);
}



/* tablets & screens up to 700px:
	oneFourth spans half the width of the screen.
*/
@media (min-width: 375px) {

	.gridItem.oneFourth {
		width: calc((100% - (2 * var(--margin)) - (4 * var(--padding)))/2);
		/* max-width: calc((100% - (2 * var(--margin)) - (4 * var(--padding)))/2); */
	}


}

/* desktops & screens 700px - 970px:
	oneFourth takes up one fourth the width of screen.
	oneThird takes up one third the width of screen.
	onHalf takes up one half the width of screen.
*/
@media (min-width: 700px) {

	.gridItem.oneFourth {
		width: calc((100% - (4 * var(--margin)) - (8 * var(--padding)))/4);
		/* max-width: calc((100% - (4 * var(--margin)) - (8 * var(--padding)))/4); */
	}

	.gridItem.oneThird {
		width: calc((100% - (3 * var(--margin)) - (6 * var(--padding)))/3);
		/* max-width: calc((100% - (3 * var(--margin)) - (6 * var(--padding)))/3); */
	}

	.gridItem.oneThird.withTwoThirds {
		width: calc((100% - (3 * var(--margin)) - (6 * var(--padding)))/3);
		/* max-width: calc((100% - (3 * var(--margin)) - (6 * var(--padding)))/3); */
	}

	.gridItem.twoThirds {
		width: calc((((100% - (3 * var(--margin)) - (6 * var(--padding)))/3) * 2) + (2 * var(--margin)));
		/* max-width: calc((((100% - (3 * var(--margin)) - (6 * var(--padding)))/3) * 2) + (2 * var(--margin))); */
	}

	.gridItem.oneHalf {
		width: calc((100% - (2 * var(--margin)) - (4 * var(--padding)))/2);
		/* max-width: calc((100% - (2 * var(--margin)) - (4 * var(--padding)))/2); */
	}

}