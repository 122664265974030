@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";
@import '../../globalStyles/responsiveLayout/spacers.css';
@import '../../globalStyles/colors/classes.css';
@import '../../globalStyles/fonts/classes.css';

.successModalWrapper {
    background-color: var(--bgPrimary);
    position: fixed;
    z-index: 1111;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    border-radius: 24px;
    border: 1px solid var(--stroke);
    box-shadow: var(--elevation02);
}