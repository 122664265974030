@import '/src/components/globalStyles/responsiveLayout/vars.css';
@import '/src/components/globalStyles/colors/vars.css';
/* 
small screen (mobile): 0 - 378 px
large screen (tablet & desktop): 378 - 970 px
*/

.contentContainer.outer {
	display: flex;
	flex-direction: column;
	align-content: center;
	width: 100%;
	max-width: 100%;
}

.contentContainer.inner {
	margin-left: var(--app-outer-margin);
	/* left margin is technically symmetrical, but looks bigger to the eye.*/
	margin-right: var(--app-outer-margin);
	max-width: 100%;
}



.testingContainers {
	box-shadow: inset 0px 0px 0px 3px var(--textPrimary);
	color: var(--textPrimary);
}

/* tablets 378px - 700px */
@media (min-width: 378px) {
	
}

/* desktops & screens 700px - 970px */
@media (min-width: 700px) {
	

}
