@import '../../globalStyles/colors/vars.css'; 
@import '../../globalStyles/responsiveLayout/vars.css'; 



.stepLoader--wrapper {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.stepLoader--stepWrapper {
    max-width: 100%;
}

.stepLoader--stepContainer {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    align-items: center;
    max-width: 100%;
    padding: 12px 6px;
    gap: 12px;
    border-radius: 12px;
    flex-grow: 1; 
    flex-shrink: 1;
    flex-basis: 0%;
}

.stepLoader--stepContainer.current {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
}

.stepLoader--indexCircle {
    /* align-self: center; */
    /* box-shadow: var(--elevation01); */
    background-color: var(--bgTertiary);
    border-radius: 50%;

    min-height: 32px;
    max-height: 32px;
    min-width: 32px;
    max-width: 32px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* margin-bottom: var(--sm-margin); */
    color: var(--textSecondary);
    font-weight: 600;
    user-select: none;
}

.stepLoader--indexCircle.current {
    /* align-self: center; */
    border: 1px solid var(--brand);
    background-color: var(--brand);
    border-radius: 50%;
    color: var(--neutralWhite);

    min-height: 32px;
    max-height: 32px;
    min-width: 32px;
    max-width: 32px;
}

.stepLoader--label {
    /* text-align: center; */

    background-color: transparent;
    color: var(--textSecondary);
    white-space: nowrap;
    font-weight: 500;
}

.stepLoader--label.current {
    color: var(--textPrimary);
    font-weight: 600;
}


.stepLoader--stepLine {
    display: none;
}

.stepLoader--stepLine.current {
    background-color: var(--grey01);
    flex-grow: 1;
}


/* tablets & screens over 900px: */
@media (min-width: 900px) {
    .stepLoader--stepLine {
        display: block;
        background-color: var(--grey01);
        height: 2px;
        border-radius: 100px;
        min-width: 40px;
        max-width: 100%;
        flex-grow: 1;
    }
}

/* desktops & screens 700px - 970px: */
@media (min-width: 700px) {
}