@import '../../globalStyles/responsiveLayout/vars.css';

.designSystemColorSq {
    margin-top: 10px;
    min-width: 90%;
    height: 50px;
    display: flex;
    flex-direction: row;
    /* horizontal center */
    justify-content: center;
    /* vertical center */
    align-items: center;
}
.designSystemColorHeader{
    padding: var(--padding);
    text-transform: capitalize;
}
