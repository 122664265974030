

.reviewTextEditor--satisfactionRating {
    transition: 500ms ease;
}

.reviewTextEditor--buttons {
    display: flex;
    margin-top: 24px;
    width: 100%;
    gap: 12px;
}

.reviewTextEditor--buttons .grow{
    display: flex;
    flex: 1;
}