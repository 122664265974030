@import '../../globalStyles/colors/vars.css';
@import '../../globalStyles/responsiveLayout/vars.css';

/* font-family: 'Avenir', 'Arial', sans-serif; */
/* ATS friendly: Arial · Calibri · Helvetica · Trebuchet · Avenir · Verdana · Montserrat · */

.textEditor--wrapper {
    box-shadow: var(--elevation01);
    max-width: 100%;   
    background-color: #FEFEFE;
    border-radius: 10px;
}

.textEditor--editor {
    padding-left: var(--padding);
    padding-right: var(--padding);
    padding-bottom: var(--padding);
    height: 500px;
    background-color: #FEFEFE;
    /* color: #1C1F23;
    font-family: 'Arial', sans-serif; */
    
}
