@import '../globalStyles/colors/vars.css';
@import '../globalStyles/fonts/classes.css';

html {
    background-color: var(--bgPrimary);
    scroll-behavior: smooth;
}

.app {
    background-color: var(--bgPrimary);
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}


