@import "/src/components/globalStyles/colors/vars.css";
@import "/src/components/globalStyles/fonts/classes.css";

.hamburger-menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.hamburger-menu-icon {
    overflow: hidden;
    display: flex;
    background-color: var(--bgPrimary);
    position: fixed;
    right: var(--app-outer-margin);
    z-index: 1003;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    min-width: 18px;
    min-height: 18px;
}

.hamburger-menu-icon:hover .hamburger-menu-icon-line {
    /* background-color: var(--brand); */
    border-radius: 100px;
}


.hamburger-menu-icon-line {
    background-color: var(--textPrimary);
    width: 100%;
    height: 2px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.hamburger-menu-icon-line:nth-child(1) {
    transform-origin: top;
}

.hamburger-menu-icon-line:nth-child(2) {
    transform-origin: middle;
}

.hamburger-menu-icon-line:nth-child(3) {
    transform-origin: bottom;
}

.hamburger-menu-icon-line--animating:nth-child(1) {
    transition-delay: 0.1s;
    transform: translateY(6px) rotate(45deg);
}

.hamburger-menu-icon-line--animating:nth-child(2) {
    opacity: 0;
    transform: translateX(101%);
}

.hamburger-menu-icon-line--animating:nth-child(3) {
    transition-delay: 0.1s;
    transform: translateY(-6px) rotate(-45deg);
}

.hamburger-menu-icon-line--open:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
}

.hamburger-menu-icon-line--open:nth-child(2) {
    transform: translateX(101%);
    opacity: 0;
}

.hamburger-menu-icon-line--open:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
}

.hamburger-menu-icon--open {
    transition: transform 0.3s ease;
    transform: rotate(90deg);
}

.hamburger-menu-menu {
    position: absolute;
    right: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    /* overflow: hidden; */
    transform: translateY(-101%);
    transition: transform 0.5s ease, opacity 0.3s ease;
    z-index: 9;
}

.hamburger-menu-menu--open {
    position: fixed;
    height: auto;
    visibility: visible;
    right: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease;
    z-index: 9;
    background-color: var(--bgPrimary);
}

.hamburger.links {
    display: flex;
    align-items: center;
    padding-bottom: 10%;
    padding-left: var(--margin);
    padding-right: var(--margin);
    padding-top: 10%;
    flex-direction: column;
    border-bottom: 1.5px solid var(--stroke);
    overflow: hidden;
}

.hamburger.links .navbar.linkWrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: var(--app-outer-margin);
}

.hamburger.links .navbar.linkWrapper a {
    text-decoration: none;
    color: var(--textPrimary);
    transition: color 0.3s ease-in-out;
}

.hamburger.links .navbar.linkWrapper a:hover {
    color: var(--brand);
}

.hamburger-icon-container {
    width: 100%;
    z-index: 9999;
    background-color: var(--bgPrimary);
}

