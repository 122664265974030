@import "/src/components/globalStyles/colors/vars.css";
@import "/src/components/globalStyles/fonts/classes.css";


.linkButton {
    padding: 10px 24px;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    transition: 200ms ease-in-out;
}

