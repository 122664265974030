/* Global Variables */
html {
    /* --brand: #0077FA;
    --brandHover: #0062D2;
    --brandActive: #004FB3; */
    --brand: #006DFE;
    --brandHover: #0a4ef8;
    --brandActive: #0031F2;
    --brandLight: rgba(0, 119, 250, 0.06);
    --brandLightHover: rgba(0, 119, 250, 0.12);

    --bgPrimary: #FEFEFE;
    --bgSecondary: #F9F9F9;
    --bgTertiary: #E6E8EA;

    --textPrimary: #121212;
    --textSecondary: #757575;
    --textTertiary: #9E9E9E;

    --neutralWhite: #FFFFFF;
    --neutralBlack: #000000;

    --info: #2F80ED;
    --infoHover:#0E74FF;
    --infoActive:#1872EC;
    --infoLight:  #F1F7FF;
    --infoLightHover:#C3DDFF;

    --warning:#FFC409;
    --warningHover:#EFB90F;
    --warningActive: #FFC200;
    --warningLight:#FEEECC;
    --warningLightHover:#FED998;

    --success:#2DD36F;
    --successHover:#1BBB5B;
    --successActive:#07D359;
    --successLight: #ECF7EC;
    --successLightHover: #D0F0D1;

    --error: #F93920;
    --errorHover: #D52515;
    --errorActive: #FF0C00;
    --errorLight: #FFF1EC;
    --errorLightHover: #FFCDC0;

    /* Shadows */
    --elevation01: 0px 1px 2px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);
    --elevation02: 0px 2px 6px 2px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
    --elevation03: 0px 4px 8px 3px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.2);
    --elevation04: 0px 6px 10px 4px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
    --elevation05: 0px 8px 12px 6px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.2);

    --grey01: #D0D4D9;
    --grey02: #A7ACB1;
    --grey03: #83888D;
    --grey04: #676D73;
    --grey05: #575B60;

    --stroke: #E0E0E0;
}

html[data-theme="dark"] {
    --brand: #006DFE;
    --brandHover: #0a4ef8;
    --brandActive: #0031F2;
    --brandLight: rgba(0, 119, 250, 0.05);
    --brandLightHover: rgba(0, 119, 250, 0.1);

    --bgPrimary: #171717;
    --bgSecondary: #1C1C1C; 
    --bgTertiary: #282828;

    --textPrimary: #FEFEFE;
    --textSecondary: #878787;
    --textTertiary: #6B6B6B;

    --info: #3474EF;
    --infoHover:#2F6ADB;
    --infoActive:#2758B5;
    /* /* --infoLight:  #F1F7FF;
    --infoLightHover:#C3DDFF; */

    /* --warning:#FFC409;
    --warningHover:#EFB90F;
    --warningActive: #FFC200;
    --warningLight:#FEEECC;
    --warningLightHover:#FED998; */

    /* --success:#57B789;
    --successHover:#4DA47A;
    --successActive:#3F8B67; */
    /* --successLight: #ECF7EC;
    --successLightHover: #D0F0D1; */

    --error: #EA6059;
    --errorHover: #CD514B;
    --errorActive: #AF443E;
    /* --errorLight: #FFF1EC;
    --errorLightHover: #FFCDC0; */

    --grey05: #D0D4D9;
    --grey04: #A7ACB1;
    --grey03: #83888D;
    --grey02: #676D73;
    --grey01: #575B60;

    --stroke: #282828;

    --elevation01: 5px 5px 10px #141414, -5px -5px 10px #1a1a1a;
    --elevation02: 9px 9px 18px #141414, -9px -9px 18px #1a1a1a;
    --elevation03: 18px 18px 36px #141414, -18px -18px 36px #1a1a1a;
    --elevation04: 23px 23px 46px #141414, -23px -23px 46px #1a1a1a;
    --elevation05: 28px 28px 56px #141414, -28px -28px 56px #1a1a1a;
}