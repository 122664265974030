@import '/src/components/globalStyles/responsiveLayout/vars.css';

.spacerHorizontal {
    width: var(--sm-margin) !important;
    max-width: 100%;
    height: 2px; /*doesn't really matter what this is as long as > 0*/
}

.spacerVertical {
    height: var(--sm-margin) !important;
    width: 2px; /*doesn't really matter what this is as long as > 0*/
}

.spacerBtwnSections {
    height: 20px;
    width: 2px; /*doesn't really matter what this is as long as > 0*/
    max-width: 100%;
}

/* tablet and desktop */
@media (min-width: 378px) {
    .spacerBtwnSections {
        height: 20px;
        width: 2px; /*doesn't really matter what this is as long as > 0*/
    }
}

/*
min-width = max-width + tablet/desktop margin
max-width = 970px
tablet/desktop margin = 24px
*/

@media (min-width: 700px) {
    .spacerBtwnSections {
        height: 50px;
        width: 2px; /*doesn't really matter what this is as long as > 0*/
    }
}