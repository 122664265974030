@import "/src/components/globalStyles/responsiveLayout/vars";

.whySection--header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: calc(var(--margin) *1);
    justify-content: center;
    text-align: center;
    margin-bottom: calc(var(--margin) * 1);
}
.whySection--logo {
    width: 195px;
    margin-right: 5px;
    margin-bottom: -1px;
}

.homeWhySection-whyMargin {
    margin-right: 15px;
}
.home-whySection--textGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.fullWidthWrapper {
    background-color: var(--bgSecondary);
}
