/* Global Variables */

 /* mobile */
html {

    --padding: 6px;
    --margin: 12px; /*970px;*/
    --sm-margin: 6px;
    --max-width: 970px;
    --app-outer-margin: 15px;
    --normalizer: 1;
    /*--app-outer-margin: var(--margin);*/


}

/* tablet and desktop */
@media (min-width: 500px) {
    html {
        --padding:12px;
        --margin: 24px; /*970px;*/
        --sm-margin: 12px;
        --app-outer-margin: 50px;
        --normalizer: calc(500/375);
    }
}

/*
min-width = max-width + tablet/desktop margin
max-width = 970px
tablet/desktop margin = 24px
*/

@media (min-width: 1018px) {
    html {
        --app-outer-margin: calc((100% - 970px)/2);
        --normalizer: calc(970/375);
    }
}
