@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";


.aboutSection--textEmphasis {
    color: var(--brand);
}

.aboutSection.text {
    color: var(--textPrimary);
}

.aboutSection.text.subheader {
    color: var(--textPrimary);
}

.aboutSection.buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    user-select: none;
}

.aboutSection.buttons.item {
    width: 140px;
    /* margin: top right bottom left */
    margin: var(--sm-margin) var(--sm-margin) 0px  0px;
}


.aboutSection.imgContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
} 

.aboutSection.img {
    max-width: 400px;
    min-width: 280px; 
} 

.aboutSectionHeading {
    margin-bottom: var(--sm-margin);
}

.aboutSection.container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
}