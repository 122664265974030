/* @import '/src/components/globalStyles/colors/vars.css'; */

.textButton {
    cursor: pointer;
    user-select: none;
    transition: 200ms ease-in-out;
}

.textButton:hover {
    color: var(--brandHover);
    transition: 300ms ease-in-out;
}

.textButton:active {
    color: var(--brandActive); 
    transition: 300ms ease-in-out;
}