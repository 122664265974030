@import './vars.css';

/***********************************************************************/
/* BACKGROUNDS                                                         */
/***********************************************************************/

/* BRAND */
.bgColor--brand {
    background-color: var(--brand);
}

/* BRAND LIGHT */
.bgColor--brandLight {
    background-color: var(--brandLight);
}

/* BACKGROUND COLORS */
.bgColor--bgPrimary {
    background-color: var(--bgPrimary);
}
.bgColor--bgSecondary {
    background-color: var(--bgSecondary);
}
.bgColor--bgTertiary {
    background-color: var(--bgTertiary);
}

/* NEUTRAL COLORS */
.bgColor--neutralWhite {
    background-color: var(--neutralWhite);
}
.bgColor--neutralBlack {
    background-color: var(--neutralBlack);
}

/* INFO */
.bgColor--info {
    background-color: var(--info);
}

/* INFO LIGHT */
.bgColor--infoLight {
    background-color: var(--infoLight);
}

/* WARNING */
.bgColor--warning {
    background-color: var(--warning);
}

/* WARNING LIGHT */
.bgColor--warningLight {
    background-color: var(--warningLight);
}

/* SUCCESS */
.bgColor--success {
    background-color: var(--success);
}

/* SUCCESS LIGHT */
.bgColor--successLight {
    background-color: var(--successLight);
}

/* ERROR */
.bgColor--error {
    background-color: var(--error);
}

/* ERROR LIGHT */
.bgColor--errorLight {
    background-color: var(--errorLight);
}

/* GREYS */
.bgColor--grey01 {
    background-color: var(--grey01);
}
.bgColor--grey02 {
    background-color: var(--grey02);
}
.bgColor--grey03 {
    background-color: var(--grey03);
}
.bgColor--grey04 {
    background-color: var(--grey04);
}
.bgColor--grey05 {
    background-color: var(--grey05);
}

/***********************************************************************/
/* BUTTONS (HAS HOVER, ACTIVE, TEXT, BACKGROUND)                                      */
/***********************************************************************/

/* BRAND */
.buColor--brand {
    background-color: var(--brand);
    color: var(--neutralWhite);
    stroke: var(--neutralWhite);
    /* border: 1.5px solid transparent; */
}

.buColor--brand:hover {
    background-color: var(--brandHover);
}

.buColor--brand:active {
    background-color: var(--brandActive);
}

.buColor--brandBorder {
    background-color: var(--bgSecondary);
    color: var(--brand);
    stroke: var(--brand);
}

.buColor--brandBorder:hover {
    background-color: var(--brandLight);
    color: var(--brandHover);
    stroke: var(--brandHover);
}

.buColor--brandBorder:active {
    color: var(--brandActive);
    stroke: var(--brandActive);
}

/* SECONDARY BUTTONS */
.buColor--secondary{
    background-color: var(--bgSecondary);
    color: var(--textPrimary);
    /* border: 1.5px solid var(--brand); */
}

.buColor--secondary:hover{
    background-color: var(--bgTertiary);
    /* border: 1.5px solid var(--brandHover); */
}

.buColor--secondary:active{
    color: var(--textPrimary);
    background-color: var(--bgSecondary);
}

/* TERTIARY BUTTONS */
.buColor--tertiary {
    background-color: transparent;
    border: 1.5px solid var(--textPrimary);
    color: var(--brand);
}

/* BRAND LIGHT */
.buColor--brandLight {
    background-color: var(--brandLight);
    color: var(--textPrimary);
}

.buColor--brandLight:hover {
    background-color: var(--brandLightHover);
}

/* NEUTRAL COLORS */
.buColor--neutralWhite{
    background-color: var(--neutralWhite);
    color: var(--neutralBlack);
}
.buColor--neutralBlack{
    background-color: var(--neutralBlack);
    color: var(--neutralWhite);
}


/* INFO */
.buColor--info {
    background-color: var(--info);
    color: var(--neutralWhite);
}

.buColor--info:hover {
    background-color: var(--infoHover);
    transition: 300ms ease;
}

.buColor--info:active {
    background-color: var(--infoActive);
    transition: 300ms ease;
}

/* INFO LIGHT */
.buColor--infoLight {
    background-color: var(--infoLight);
    color: var(--textPrimary);
}

.buColor--infoLight:hover {
    background-color: var(--infoLightHover);
    transition: 300ms ease;
}

/* WARNING */
.buColor--warning {
    background-color: var(--warning);
    color: var(--neutralWhite);
}

.buColor--warning:hover {
    background-color: var(--warningHover);
    transition: 300ms ease;
}

.buColor--warning:active {
    background-color: var(--warningActive);
    transition: 300ms ease;
}

/* WARNING LIGHT */
.buColor--warningLight {
    background-color: var(--warningLight);
    color: var(--textPrimary);
}

.buColor--warningLight:hover {
    background-color: var(--warningLightHover);
    transition: 300ms ease;
}

/* SUCCESS */
.buColor--success {
    background-color: var(--success);
    color: var(--neutralWhite);
}

.buColor--success:hover {
    background-color: var(--successHover);
    transition: 300ms ease;
}

.buColor--success:active {
    background-color: var(--successActive);
    transition: 300ms ease;
}

/* SUCCESS LIGHT */
.buColor--successLight {
    background-color: var(--successLight);
    color: var(--textPrimary);
}

.buColor--successLight:hover {
    background-color: var(--successLightHover);
    transition: 300ms ease;
}

/* ERROR */
.buColor--error {
    background-color: var(--error);
    color: var(--neutralWhite);
}

.buColor--error:hover {
    background-color: var(--errorHover);
    transition: 300ms ease;
}

.buColor--error:active {
    background-color: var(--errorActive);
    transition: 300ms ease;
}

.buColor--errorBorder {
    background-color: var(--bgSecondary);
    color: var(--error);
    stroke: var(--error);
}

.buColor--errorBorder:hover {
    color: var(--errorHover);
    stroke: var(--errorHover);
    background-color: var(--errorLight);
}

.buColor--errorBorder:active {
    color: var(--errorActive);
    stroke: var(--errorActive);
}


/* ERROR LIGHT */
.buColor--errorLight {
    background-color: var(--errorLight);
    color: var(--textPrimary);
}

.buColor--errorLight:hover {
    background-color: var(--errorLightHover);
    transition: 300ms ease;
}

/* BUTTON SHADDOWS */
.buColor--shadow {
    box-shadow: none;
}

/* .buColor--shadow:hover {
    box-shadow: var(--elevation01);
    transition: 300ms ease;
}

.buColor--shadow:active {
    box-shadow: var(--elevation01);
    transition: 300ms ease;
} */

.buColor--shadow:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: var(--textPrimary);
    background-color: var(--grey01);
    border: 2px solid transparent;
}

.buColor--shadow:disabled {
    transform: translateY(0px);
}

/***********************************************************************/
/* TEXT                                                                */
/***********************************************************************/

/* BRAND */
.txtColor--brand {
    color: var(--brand);
}

/* BRAND LIGHT */
.txtColor--brandLight {
    color: var(--brandLight);
}

/* TEXT COLORS */
.txtColor--textPrimary {
    color: var(--textPrimary);
}
.txtColor--textSecondary {
    color: var(--textSecondary);
}
.txtColor--textTertiary {
    color: var(--textTertiary);
}

/* NEUTRAL COLORS */
.txtColor--neutralWhite {
    color: var(--neutralWhite);
}
.txtColor--neutralBlack {
    color: var(--neutralBlack);
}

/* INFO */
.txtColor--info {
    color: var(--info);
}

/* INFO LIGHT */
.txtColor--infoLight {
    color: var(--infoLight);
}

/* WARNING */
.txtColor--warning {
    color: var(--warning);
}

/* WARNING LIGHT */
.txtColor--warningLight {
    color: var(--warningLight);
}

/* SUCCESS */
.txtColor--success {
    color: var(--success);
}

/* SUCCESS LIGHT */
.txtColor--successLight {
    color: var(--successLight);
}

/* ERROR */
.txtColor--error {
    color: var(--error);
}

/* ERROR LIGHT */
.txtColor--errorLight {
    color: var(--errorLight);
}

/* GREYS */
.txtColor--grey01 {
    color: var(--grey01);
}
.txtColor--grey02 {
    color: var(--grey02);
}
.txtColor--grey03 {
    color: var(--grey03);
}
.txtColor--grey04 {
    color: var(--grey04);
}
.txtColor--grey05 {
    color: var(--grey05);
}

/* STROKE */
.stroke--stroke {
    color: var(--stroke);
}

/***********************************************************************/
/* SHADOWS                                                             */
/***********************************************************************/

.shadow--elevation01 {
    box-shadow: var(--elevation01);
}
.shadow--elevation02 {
    box-shadow: var(--elevation02);
}
.shadow--elevation03 {
    box-shadow: var(--elevation03);
}
.shadow--elevation04 {
    box-shadow: var(--elevation04);
}
.shadow--elevation05 {
    box-shadow: var(--elevation05);
}
