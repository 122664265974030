@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";

.satisfactionRating--wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;
    transition: 1000ms ease;
    position: relative;
    opacity: 1;
}

.satisfactionRating--buttonsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.satisfactionRating--label {
    align-self: center;
}

.satisfactionRating--wrapper.fade-out{
    animation-name: fade-out;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-duration: 500ms;
    opacity: 0;
    pointer-events: none;
    transition: 1000ms ease;
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
