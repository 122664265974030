/* @import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";
*/

.signUpFormTermsLink {
    cursor: pointer;
}

.signUpFormTermsLink:hover {
    transform: translateY(-2px);
    transition: 300ms ease;

}

.signUpFormTermsLink:active {
    transform: translateY(-2px);
    transition: 300ms ease;
}