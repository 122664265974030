@import '../colors/vars.css';
@import '../responsiveLayout/vars.css';
@import '../fonts/classes.css';

.formsTextInputWrapper {
    max-width: 100%;
    width: 100%;
    /* min-width: 100%; */
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.formsTextInput{
    font-weight: 400;
    border-radius: 10px;
    border: 2px solid var(--stroke);
    background-color: var(--bgSecondary);
    color: var(--textPrimary);
    /* text padding */
    padding: 12px;
    transition: 200ms ease;
    outline: none;
}
.formsTextInput::placeholder {
    color: var(--textTertiary);
    font-weight: 400;
    transition: 300ms ease;
}
.formsTextInput:focus-visible {
    color: var(--textPrimary);
    /* outline: 1px solid var(--brandLight); */
    border: 2px solid var(--brand);
    background-color: var(--bgPrimary);
    transition: 200ms ease;
}

.formsTextInput.error {
    border: 2px solid var(--error);
}

.formsQuestionLabel {
    font-weight: 500;
    color: var(--textPrimary);
    user-select: none;
}

.formsQuestionSubLabel {
    font-weight: 400;
    margin-bottom: 5px;
    color: var(--textSecondary) !important;
    user-select: none;
}
.formsQuestionSubLabel.error {
    color: var(--error) !important;
}

.formsDropdownButton {
	border-radius: 10px;
	border: 2px solid var(--stroke);
	background-color: var(--bgSecondary);
    padding: 12px;
	width: 100% !important;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	transition: 200ms ease;
	outline: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
}
/* this is intentionally a mod and not a state
for placeholder, since buttons don't have palceholders
but since we are styling the button like an input it does
in this case. */
.formsDropdownButton.placeholder {
    color: var(--textTertiary);
    font-weight: 400;
    transition: 200ms ease;
}
.formsDropdownButton:focus {
    color: var(--brand);
    outline: 1px solid var(--brandLight);
    border: 2px solid var(--brand);
    transition: 200ms ease;
}

.formsDropdownButton.selected {
  color: var(--textPrimary) !important;
}
.formsDropdownButton:focus .dropdownIcon {
  transform: rotate(0deg);
}
.basicDropdownButton.selected:focus {
	border: 2px solid var(--brand);
	/* outline: 1px solid var(--brandLight); */
	color: var(--brand) !important;
}
.formsDropdown {
	display: flex;
	flex-direction: column;
	gap: 5px;
	position: relative;
	cursor: pointer;
}
.formsDropdownList {
	list-style-type: none;
	padding-left: 0px;
	padding-top: 5px;
	padding-bottom: 5px;
	border: 2px solid var(--stroke);
	border-radius: 5px;
	margin: 0;
}
.formsDropdownListItem {
	font-family: inherit;
	cursor: pointer;
	padding: 10px 10px;
}
.formsDropdownListItem:hover {
	background-color: var(--bgTertiary);
    border-radius: 5px;
}
.formsDropdownListItem.selected {
	color: var(--brand);
}
.formsDropdownIcon {
    padding: 10px;
	position: absolute;
	right: 0px;
}
/* rotates drop down arrow when options visible */
.formsDropdownIcon.show {
	transform: rotate(360deg);
    transition: transform 200ms ease;
}

.formsDropdownIcon.show path {
    stroke: var(--brand) !important;
}

/* rotates drop down arrow when options hidden */
.formsDropdownIcon.hide {
	transform: rotate(270deg);
    transition: transform 200ms ease;
}
.formsDropdownIcon path {
	stroke: var(--textTertiary);
}

.formsTagsWrapper {
    display: flex;
    flex-direction: row;
    gap: 5px;
    cursor: default;
}
.formsTag {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    height: 36px;
    padding-right: 0;
    border-radius: 5px;
    /* border: 1px solid var(--brandLight); */
    background-color: var(--brandLight);
    max-width: fit-content;
    align-items: center;
    color: var(--brand);
    cursor: default;
}

.formsTagRemoveButton {
    background: none;
    border: none;
    color: var(--brand);
    font-size: 18px;
    cursor: pointer;
    padding: 10px;
}
