/* CASEY NEEDS TO FINISH THE SCALING ALGS */
/* DYL NEEDS TO SET MIN VALUES */

html {
	/* min values */
	--displayLarge: 40px;
	--displaySmall: 25px;
	--headlineLarge: 22px;
	--headlineSmall: 20px;
	--titleLarge: 20px;
	--titleSmall: 16px;
	--subheadingLarge: 18px;
	--subheadingSmall: 14px;
	--bodyLarge: 16px;
	--bodySmall: 14px;
	--labelLarge: 16px;
	--labelSmall: 14px;
	--footnote: 13px;
	--caption1: 10px;
	--cabption2: 10px;
}

/* styles for mobile */
.displayLarge {
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: 700;
	/*should be around 46 for desktop & 28.5 for mobile & tablet*/
	font-size: var(--displayLarge);
	line-height: calc(var(--displayLarge) * 1.1);
	letter-spacing: -0.25px;
}

.displaySmall {
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: 700;
	font-size: var(--displaySmall);
	line-height: calc(var(--displaySmall) * 1.2);
	letter-spacing: -0.25px;
}

.headlineLarge {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: var(--headlineLarge);
	line-height: calc(var(--headlineLarge) * 1.2);
}

.headlineSmall {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: var(--headlineSmall);
	line-height: calc(var(--headlineSmall) * 1.2);
}

.titleLarge {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: var(--titleLarge);
	line-height: calc(var(--titleLarge) * 1.2);
}

.titleSmall {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 16px;
	font-size: var(--titleSmall);
	line-height: calc(var(--titleSmall) * 1.2);
}

.subheadingLarge {
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: normal;
	font-size: var(--subheadingLarge);
	line-height: calc(var(--subheadingLarge) * 1.4);
}

.subheadingSmall {
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: normal;
	font-size: var(--subheadingSmall);
	line-height: calc(var(--subheadingSmall) * 1.3);
}

.bodyLarge {
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: normal;
	font-size: var(--bodyLarge);
	line-height: calc(var(--bodyLarge) * 1.3);
}

.bodySmall {
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: normal;
	font-size: var(--bodySmall);
	line-height: calc(var(--bodySmall) * 1.3);
	letter-spacing: 0.4px;
}

.labelLarge {
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: 500;
	font-size: var(--labelLarge);
	line-height: calc(var(--labelLarge) * 1.3);
	letter-spacing: 0.1px;
}

.labelSmall {
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: 500;
	font-size: var(--labelSmall);
	line-height: calc(var(--labelSmall) * 1.3);
	letter-spacing: 0.5px;
}

.footnote {
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: normal;
	font-size: var(--footnote);
	line-height: calc(var(--footnote) * 1.3);
}

.caption1 {
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	font-size: var(--caption1);
	line-height: calc(var(--caption1) * 1.2);
}

.caption2 {
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: normal;
	font-size: var(--caption2);
	line-height: calc(var(--caption2) * 1.2);
}

/* styles for tablet & desktop */
/* maybe make these scale to be larger as screen grows */
@media (min-width: 378px) {
	.displayLarge {
		font-size: calc(var(--displayLarge) + .100001vw);
		line-height: calc((var(--displayLarge) + .100001vw) * 1.2);
	}

	.displaySmall {
		font-size: calc(var(--displaySmall) + .100001vw);
		line-height: calc((var(--displaySmall) + .100001vw) * 1.2);
	}

	.headlineLarge {
		font-size: calc(var(--headlineLarge) + .100001vw);
		line-height: calc((var(--headlineLarge) + .100001vw) * 1.2);
	}

	.headlineSmall {
		font-size: calc(var(--headlineSmall) + .100001vw);
		line-height: calc((var(--headlineSmall) + .100001vw) * 1.3);
	}

	.titleLarge {
		font-size: calc(var(--titleLarge) + .100001vw);
		line-height: calc((var(--titleLarge) + .100001vw) * 1.3);
	}

	.titleSmall {
		font-size: calc(var(--titleSmall) + .100001vw);
		line-height: calc((var(--titleSmall) + .100001vw) * 1.3);
	}

	.subheadingLarge {
		font-size: calc(var(--subheadingLarge) + .100001vw);
		line-height: calc((var(--subheadingLarge) + .100001vw) * 1.4);
	}

	.subheadingSmall {
		font-size: calc(var(--subheadingSmall) + .100001vw);
		line-height: calc((var(--subheadingSmall) + .100001vw) * 1.3);
	}
/* small should remain static sizes */
	/* .bodyLarge {
		font-size: calc(var(--bodyLarge) + .100001vw);
		line-height: calc((var(--bodyLarge) + .100001vw) * 1.2);
	}

	.bodySmall {
		font-size: calc(var(--bodySmall) + .100001vw);
		line-height: calc((var(--bodySmall) + .100001vw) * 1.2);
	}

	.labelLarge {
		font-size: calc(var(--labelLarge) + .100001vw);
		line-height: calc((var(--labelLarge) + .100001vw) * 1.2);
	}

	.labelSmall {
		font-size: calc(var(--labelSmall) + .100001vw);
		line-height: calc((var(--labelSmall) + .100001vw) * 1.2);
	}

	.footnote {
		font-size: calc(var(--footnote) + .100001vw);
		line-height: calc((var(--footnote) + .100001vw) * 1.2);
	}

	.caption1 {
		font-size: calc(var(--caption1) + .100001vw);
		line-height: calc((var(--caption1) + .100001vw) * 1.2);
	}

	.caption2 {
		font-size: calc(var(--caption2) + .100001vw);
		line-height: calc((var(--caption2) + .100001vw) * 1.2);
	} */
}

