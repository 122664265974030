@import "/src/components/globalStyles/responsiveLayout/vars";
@import "/src/components/globalStyles/colors/vars";


.satisfactionButtonImg--path {
    stroke: var(--brand);
}

.satisfactionButtonImg--wrapper {
    
    display: flex;
    flex-direction: column;
    /* padding left needed to center img */
    /* height: 200px; */
    /* background-color: blue; */
} 

.satisfactionButtonImg--svg {
    max-width: 100%;
    max-height: 100%;
    /* background-color: blueviolet; */
    align-self: center;
}

/* tablets 378px - 700px */
@media (min-width: 378px) {

}

/* desktops & screens 700px - 970px */
@media (min-width: 700px) {


}